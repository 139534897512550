import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { kebabCase } from 'lodash';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import withLayout from '../layout';
import SEO from '../components/seo';

import './tagsTemplate.scss';


const Tags = ({ pageContext, data, intl }) => {
    const { tag } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const titleText = intl.formatMessage({id: 'blog.tags-title'});

    return (
        <main className="posts" role="main">
            <SEO
                keywords={['JavaScript', 'D3.js', 'Software Engineer', 'Web Performance']}
                lang={pageContext.locale}
                title={titleText}
            />
            <section className="entries-wrapper">
                <div className="container">
                    <div className="pure-g-r">
                        <div className="pure-u-1-3">
                            <header className="entries-wrapper-hd" role="banner">
                                <h2 className="h2 entries-title">
                                    <FormattedMessage
                                        id="blog.tags-intro"
                                        values={{
                                            totalCount: totalCount,
                                            pluralizer: totalCount === 1 ? '' : 's',
                                            tag: tag,
                                        }}
                                    />
                                </h2>
                                <div className="entries-intro">
                                    <Link className="link" to="/tags">All tags</Link>
                                </div>
                            </header>
                        </div>
                        <div className="pure-u-2-3">
                            <div className="entries-wrapper-bd">
                                <ul className="project-list plain-list">
                                    {edges.map(({node}) => (
                                        <li key={node.id} className="entry">
                                            <div className="entry-inner">
                                                <div className="entry-bd clearfix">
                                                    <div className="media">
                                                        <div className="media-figure">
                                                            <Link className="img-link" to={node.fields.slug}>
                                                                <GatsbyImage image={node.frontmatter.thumbnail.childImageSharp.gatsbyImageData} />
                                                            </Link>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="entry-metadata">
                                                                <h3 className="h3 entry-title">
                                                                    <Link className="link" to={node.fields.slug}>
                                                                        {node.frontmatter.title}
                                                                    </Link>
                                                                </h3>
                                                                <h4 className="h4 entry-summary">{node.frontmatter.excerpt}</h4>
                                                                {node.frontmatter.tags ? (
                                                                    <div className="tags-container">
                                                                        <ul className="tag-pool">
                                                                            {node.frontmatter.tags.map(tag => (
                                                                                <li key={tag + `tag`} className="tag-item">
                                                                                    <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

const customProps = {
    localeKey: 'tags',
};

export default withLayout(customProps)(injectIntl(Tags));

export const pageQuery = graphql`query ($tag: String) {
  allMarkdownRemark(
    limit: 2000
    sort: {fields: [frontmatter___date], order: ASC}
    filter: {frontmatter: {tags: {in: [$tag]}}}
  ) {
    totalCount
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 176, height: 99, layout: FIXED)
            }
          }
          excerpt
        }
      }
    }
  }
}
`;
